import React from "react";
import styled from "styled-components";
import { StyledWidthWrapper } from "./credentialsPage/Section3";
import { BodyText, breakPoints } from "./theme";
import { useTranslation } from "react-i18next";

const CardWrapper = styled.div`
  height: 100%;
  padding: 24px;
  background: ${(props) => props.theme.btnPrimaryColor};
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.04);
`;
const LogoWrapper = styled.span`
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text_greyDark};
  svg {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  p {
    margin-right: 0.75em;

    @media (max-width: 480px) {
      margin-right: 0.5em;
    }
  }
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 1.25;
  margin-bottom: 2em;
  color: ${(props) => props.theme.grey3};
`;

const Section = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  background: ${(props) => props.theme.brandGrey};
  @media (max-width: ${breakPoints.sm}px) {
    padding-top: 1rem;
  }
`;

export const StyledNumber = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  height: 25px;
  background: ${(props) => props.theme.darkBlue};
  border-radius: 50%; ;
`;

const StyledCardWrapper = styled(CardWrapper)`
  width: 100%;
  margin: 0 auto;
  padding: 1.5em;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakPoints.sm}px) {
    padding: 48px 24px;
  }
`;

const RowsWrapper = styled.div`
  span {
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.text_greyDark};
  }
  div:nth-of-type(even) {
    background: white;
  }

  div:nth-of-type(odd) {
    background: ${(props) => props.theme.navHover};
  }
  div:nth-child(1) {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${(props) => props.theme.text_primary_dark};
    background: ${(props) => props.theme.borderBg};
    padding-left: 1em;
    padding-right: 0;
  }
`;
const StyledRow = styled.div`
  margin: 0 auto;
  max-width: 75%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 34px;
  padding-left: 1em;

  svg {
    cursor: pointer;
  }
  span:nth-of-type(odd) {
    width: 40%;
  }

  span:nth-of-type(even) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 50%;
  }

  @media (max-width: ${breakPoints.lg}px) {
    max-width: 100%;
  }
`;

export default function CheckList() {
  const { t } = useTranslation();
  return (
    <Section>
      <StyledWidthWrapper>
        <Title>{t("credentials.checklist.title")}</Title>
        <StyledCardWrapper>
          <BodyText margin="0 0 1.5rem">
            {t("credentials.checklist.description")}
          </BodyText>
          <RowsWrapper>
            <StyledRow>
              <span> {t("credentials.inforamtion")}</span>
              <span title="Content">{t("credentials.content")}</span>
            </StyledRow>

            <StyledRow>
              <LogoWrapper>
                <StyledNumber>1</StyledNumber>
                {t("credentials.firstname")}
              </LogoWrapper>
              <span title="Miriam">Miriam</span>
            </StyledRow>

            <StyledRow>
              <LogoWrapper>
                <StyledNumber>2</StyledNumber>
                {t("credentials.lastname")}
              </LogoWrapper>
              <span title="Rosentzvaig">Rosentzvaig</span>
            </StyledRow>

            <StyledRow>
              <LogoWrapper>
                <StyledNumber>3</StyledNumber>
                {t("credentials.country")}
              </LogoWrapper>
              <span title="Malta">Malta</span>
            </StyledRow>

            <StyledRow>
              <LogoWrapper>
                <StyledNumber>4</StyledNumber>
                {t("credentials.email")}
              </LogoWrapper>
              <span title="miriam@gmail.com">miriam@gmail.com</span>
            </StyledRow>

            <StyledRow>
              <LogoWrapper>
                <StyledNumber>5</StyledNumber>
                {t("credentials.address")}
              </LogoWrapper>
              <span title="1123 High Street, Block...">
                1123 High Street, Block...
              </span>
            </StyledRow>

            <StyledRow>
              <LogoWrapper>
                <StyledNumber>6</StyledNumber>
                {t("credentials.passport")}
              </LogoWrapper>
              <span title="Rosentzvaig">Rosentzvaig</span>
            </StyledRow>
          </RowsWrapper>
        </StyledCardWrapper>
      </StyledWidthWrapper>
    </Section>
  );
}
