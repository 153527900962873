import React from "react";
import styled from "styled-components";
import EllipseIcon from "../../images/ellipse.svg";
import { breakPoints } from "../theme";
import { useTranslation } from "react-i18next";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 1;
  color: ${(props) => props.theme.darkBlue};

  svg {
    margin-right: 8px;
  }
  img {
    margin-right: 8px;
  }
`;

const CardWrapper = styled.div`
  height: 100%;
  padding: 1.5em;
  background: ${(props) => props.theme.btnPrimaryColor};
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.04);

  span {
    font-family: "Telex", sans-serif;
  }
`;

export const SectionWrapper = styled.section`
  width: 100%;
  margin-top: 3rem;
  background: ${(props) => props.theme.brandGrey};
  @media (max-width: ${breakPoints.sm}px) {
    margin-top: 0;
  }
`;

export const WidthWrapper = styled.div`
  width: 65%;
  margin: 0 auto;
  padding: 80px 0px;
  @media (max-width: ${breakPoints.sm}px) {
    padding: 0 24px 80px;
    width: 100%;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 20px;
  justify-content: start;
  span {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.text_greyDark};
  }
  @media (max-width: ${breakPoints.sm}px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
`;

const CardTitle = styled.div`
  font-weight: bold;
  font-size: 1.25em;
  line-height: 1.25;
  color: ${(props) => props.theme.grey3};
  margin: 1.5em 0;
`;

export default function SecondSection() {
  const { t } = useTranslation();
  return (
    <SectionWrapper>
      <WidthWrapper>
        <CardsWrapper>
          <CardWrapper>
            <LogoWrapper>
              <img src={EllipseIcon} alt="Ellipse icon" />
              {t("credentials.defiCredentials.header")}
            </LogoWrapper>
            <CardTitle>{t("credentials.defiCredentials.title")}</CardTitle>
            <span>
              {t("credentials.defiCredentials.description")}
              <br />
              <br />
              {t("credentials.defiCredentials.description2")}
            </span>
          </CardWrapper>
        </CardsWrapper>
      </WidthWrapper>
    </SectionWrapper>
  );
}
