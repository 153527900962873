import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Logo from "../images/logo.svg";
import { breakPoints } from "./theme";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { Box } from "./Box";

import "./menu.css";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.text_primary};

  svg {
    margin-right: 8px;
  }
  img {
    margin-right: 8px;
  }
`;
export const StyledButton = styled(Button)`
  padding: 10px 24px;
  box-shadow: 0px 4px 8px rgba(0, 202, 236, 0.2);
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 24px;
`;
const StyledLink = styled(Link)`
  margin-right: 40px;
  color: ${(props) => props.theme.text_greyDark};
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;

  :hover {
    color: ${(props) => props.theme.brandBlue};
  }
`;
const StyledHeader = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  background: ${(props) => props.theme.btnPrimaryColor};
  box-shadow: 0px 6px 8px rgba(105, 124, 149, 0.7);
  height: 79px;
  display: flex;
  align-items: center;
  z-index: 5;
`;

export const WidthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin: 0 auto;

  @media (max-width: ${breakPoints.xl}px) {
    width: 100%;
    justify-content: center;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: ${breakPoints.sm}px) {
    padding: 0 24px;
  }
`;

// Mobile Menu

const MobileMenuContainer = styled.div`
  right: 0px;
  top: 0;
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 16px;
  padding-top: 60px;
  width: 60vw;
  box-shadow: 0px 6px 8px rgba(105, 124, 149, 0.7);
  z-index: 5;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: ${({ show }) => (show ? "translate(0, 0)" : "translate(100%, 0)")};
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  height: 100vh;
`;

const MobileMenuOverlay = styled.div`
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

const MenuButtonContainer = styled.div`
  position: absolute;
  right: 24px;
  top: 30px;
  z-index: 3;
`;

function MobileMenu(props) {
  const [showMenu, setShowMenu] = useState(false);

  const handleClose = () => {
    setShowMenu(false);
  };

  const handleMenuClick = () => setShowMenu(!showMenu);

  return (
    <>
      <MenuButtonContainer>
        <div id="menuToggle">
          <input
            type="checkbox"
            onChange={handleMenuClick}
            checked={showMenu}
          />
          <span />
          <span />
          <span />
        </div>
      </MenuButtonContainer>
      {showMenu && <MobileMenuOverlay onClick={handleClose} />}
      <MobileMenuContainer show={showMenu}>
        {props.children({ handleClose })}
      </MobileMenuContainer>
    </>
  );
}

const Header = () => {
  const { t } = useTranslation();
  return (
    <StyledHeader>
      <WidthWrapper>
        <HeaderWrapper>
          <Box autoWidth flex={1}>
            <Link to="/" style={{ display: "flex" }}>
              <LogoWrapper>
                <img src={Logo} alt="KeyFi logo" />
              </LogoWrapper>
            </Link>
          </Box>

          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Box mobileHidden alignItems="center">
              <StyledLink to="/credentials">
                {t("footer.about")} {t("footer.credential")}
              </StyledLink>
              <Box marginLeft={66}>
                <a
                  href="https://app.keyfi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledButton>{t("homepage.menu.launchApp")}</StyledButton>
                </a>
              </Box>
            </Box>
            <Box desktopHidden>
              <MobileMenu>
                {({ handleClose }) => {
                  return (
                    <Box display="flex" col height="100%">
                      <Box flex={1} height="100%" col>
                        <Box display="block">
                          <StyledLink to="/credentials" onClick={handleClose}>
                            {t("footer.about")} {t("footer.credential")}
                          </StyledLink>
                        </Box>
                      </Box>
                      <Box
                        flex={0}
                        display="flex"
                        style={{ width: "100%" }}
                        marginBottom={150}
                      >
                        <a
                          href="https://app.keyfi.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleClose}
                          style={{ width: "100%" }}
                        >
                          <StyledButton style={{ width: "100%" }}>
                            {t("homepage.menu.launchApp")}
                          </StyledButton>
                        </a>
                      </Box>
                    </Box>
                  );
                }}
              </MobileMenu>
            </Box>
          </Box>
        </HeaderWrapper>
      </WidthWrapper>
    </StyledHeader>
  );
};

export default Header;
