import React from "react";
import styled from "styled-components";
// import { Link } from "gatsby";
import Animation from "../../images/secondSectionAnimation.svg";
import SelfkeyLogo from "../../images/selfkey2.svg";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import { Box } from "../Box";
import { breakPoints } from "../theme";
import { WidthWrapper } from "../Header";

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 4em;
  line-height: 1.25;
  max-width: 580px;
  margin-bottom: 32px;
  margin-top: 79px;
  text-transform: uppercase;

  @media (max-width: ${breakPoints.sm}px) {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
  }
`;

export const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  color: ${(props) => props.theme.grey3};
  justify-content: space-between;
  align-items: center;
  // margin: 80px auto 93px auto;
  margin: 5em auto 1em;

  span {
    max-width: 480px;
    margin-bottom: 32px;
    font-size: 24px;
    font-family: "Telex", sans-serif;
    line-height: 32px;
    @media (max-width: ${breakPoints.sm}px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  button {
    margin-right: 1rem;
  }

  @media (max-width: ${breakPoints.xl}px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${breakPoints.sm}px) {
    padding: 3em 1.5em 0;
  }
`;

const Title = styled(StyledTitle)`
  @media (max-width: ${breakPoints.sm}px) {
    font-size: 2.3em;
    line-height: 1.2;
    margin-top: 0px;
  }
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    font-size: 3em;
    line-height: 1.2;
    margin-top: 1em;
  }
`;

const ButtonsBox = styled(Box)`
  display: flex;
  width: auto;
  @media (max-width: ${breakPoints.sm}px) {
    flex-direction: column;
  }
`;

// const ExtendedStyledLink = styled(Link)`
//   // color: ${(props) => props.theme.text_secondary};
//   text-transform: uppercase;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 24px;
//   text-decoration: none;
// `;

const ImageBox = styled(Box)`
  margin-top: 25px;
  @media (max-width: ${breakPoints.sm}px) {
    margin-top: 0px;
    justify-content: center;
    svg {
      width: 80%;
    }
  }

  @media (max-width: ${breakPoints.xl}px) {
    display: none;
  }
`;

const PoweredByText = styled.h4`
  align-items: center;
  display: flex;
  font-size: 0.7em;
  font-weight: 500;
  margin-left: 1em;
  margin: 0 auto 0 1.5em;

  img {
    height: 30px;
    margin-left: 0.75em;
  }

  @media (max-width: ${breakPoints.lg}px) {
    margin: 1em auto 0;
    text-align: center;
    justify-content: center;
  }
`;

export default function Section1() {
  const { t } = useTranslation();
  return (
    <>
      <WidthWrapper>
        <InnerWrapper>
          <Box col>
            <Title>{t("credentials.title")}</Title>
            <span>{t("credentials.description")}</span>

            <div>
              {/* <Button weight="bold" uppercase padding="10px 24px">
            <a href="https://kyc.keyfi.ai" target="_blank">Apply for Credentials</a>
          </Button>
          <Button weight="bold" secondary padding="10px 24px">
            read more
          </Button> */}
              <ButtonsBox
                flex={0}
                desktopHidden
                width="100%"
                marginTop={8}
                flexDirection="column"
              >
                <a
                  href="https://selfkey.org/download/"
                  style={{ width: "100%" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    weight="bold"
                    padding="12px 24px"
                    style={{ width: "100%", fontSize: 16 }}
                  >
                    {t("credentials.useWalletBtn")}
                  </Button>
                  <PoweredByText>
                    Powered by <img src={SelfkeyLogo} alt="Selfkey logo" />
                  </PoweredByText>
                </a>
                <Box
                  width="100%"
                  display="block"
                  textAlign="center"
                  marginTop={36}
                >
                  {/*<ExtendedStyledLink
                  to="#"
                  style={{ textAlign: "center", fontSize: 16 }}
                >
                  Web Credentials Coming Soon
                </ExtendedStyledLink>*/}
                </Box>
              </ButtonsBox>

              <ButtonsBox flex={0} mobileHidden>
                <Box flex={0}>
                  <a
                    href="https://selfkey.org/download/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      weight="bold"
                      padding="12px 24px"
                      style={{ width: "100%", fontSize: 16 }}
                    >
                      {t("credentials.useWalletBtn")}
                    </Button>
                  </a>
                  <PoweredByText>
                    Powered by <img src={SelfkeyLogo} alt="Selfkey logo" />
                  </PoweredByText>
                </Box>
                <Box marginLeft={32} padding={8}>
                  {/*<ExtendedStyledLink to="#" style={{ fontSize: 16 }}>
                  Web Credentials Coming Soon
                </ExtendedStyledLink>*/}
                </Box>
              </ButtonsBox>
            </div>
          </Box>
          <ImageBox>
            <img src={Animation} alt="animation" />
          </ImageBox>
        </InnerWrapper>
      </WidthWrapper>
    </>
  );
}
