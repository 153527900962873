import React from "react";
import styled from "styled-components";

import { StyledNumber } from "../CheckList";
import { breakPoints } from "../theme";

import { Section, StyledWidthWrapper as WidthWrapper } from "./Section3";
import { useTranslation } from "react-i18next";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.brandBlue};

  svg {
    margin-right: 8px;
  }
  img {
    margin-right: 8px;
  }
`;

const StyledLogoWrapper = styled(LogoWrapper)`
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  color: ${(props) => props.theme.text_greyDark};
  svg {
    margin-right: 16px;
  }
`;
const Title = styled(StyledLogoWrapper)`
  p {
    margin-right: 8px;
    width: 28px;
    height: 28px;
  }
`;

const CardWrapper = styled.div`
  height: 100%;
  padding: 24px;
  background: ${(props) => props.theme.btnPrimaryColor};
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.04);
`;
const HeaderWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ListWrapper = styled.div`
  max-width: 70%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
    max-width: 100%;
  }

  div {
    margin-top: 15px;
  }
`;

const CardTitle = styled.div`
  max-width: 400px;
  font-weight: bold;
  font-size: 1.25em;
  line-height: 1.25;
  color: ${(props) => props.theme.grey3};
  margin: 0 0 1em;
`;

export default function ForthSection() {
  const { t } = useTranslation();
  return (
    <Section>
      <WidthWrapper>
        <CardWrapper>
          <HeaderWrapper>
            <CardTitle>
              {t("credentials.credentialsRequirementandForms")}
            </CardTitle>
            {/*            <Button padding="10px 24px" weight="bold" uppercase>
              Apply for Credentials
            </Button>*/}
          </HeaderWrapper>
          <ListWrapper>
            <div>
              <Title>
                <StyledNumber>1</StyledNumber>
                {t("credentials.fullname")}
              </Title>

              <Title>
                <StyledNumber>2</StyledNumber>
                {t("credentials.passport")}
              </Title>

              <Title>
                <StyledNumber>3</StyledNumber>
                {t("credentials.country")}
              </Title>
            </div>

            <div>
              <Title>
                <StyledNumber>4</StyledNumber>
                {t("credentials.email")}
              </Title>

              <Title>
                <StyledNumber>5</StyledNumber>
                {t("credentials.dob")}
              </Title>
            </div>
          </ListWrapper>
        </CardWrapper>
      </WidthWrapper>
    </Section>
  );
}
