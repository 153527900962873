import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "../components/theme";
import MetaComponent from "../components/MetaComponent";
import FirstSection from "../components/credentialsPage/Section1";
import SecondSection from "../components/credentialsPage/Section2";
import ThirdSection from "../components/credentialsPage/Section3";
import ForthSection from "../components/credentialsPage/Section4";
import CheckList from "../components/CheckList";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import "./index.css";
import { graphql } from "gatsby";

const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
  background: white;
`;
export default function Credentials() {
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="Credentials" />
      <Wrapper>
        <Menu activeMenu="credentials" credential={true} />
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <ForthSection />
        <CheckList />
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
