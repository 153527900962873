import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { breakPoints } from "./theme";

const boxStyleProps = _.flatten([
  "position",
  "left",
  "right",
  "top",
  "bottom",
  "textAlign",
  "marginBottom",
  "marginTop",
  "marginLeft",
  "marginRight",
  "paddingLeft",
  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "backgroundColor",
  "borderRadius",
  "margin",
  "padding",
  "flex",
  "flexDirection",
  "alignItems",
  "justifyContent",
  "flexWrap",
  "display",
  "borderWidth",
  "borderColor",
  "width",
  "height",
  "maxWidth",
  ["borderBottom", "borderTop", "borderLeft", "borderRight"].map((opt) => [
    `${opt}Width`,
    `${opt}Color`,
    `${opt}Style`,
  ]),
]);

const BoxContainer = styled.div`
  display: inline;
  @media (max-width: ${breakPoints.lg}px) {
    display: ${({ mobileHidden }) =>
      mobileHidden ? "none !important" : "inherit"};
  }
  @media (min-width: ${breakPoints.lg}px) {
    display: ${({ desktopHidden }) =>
      desktopHidden ? "none !important" : "inherit"};
  }
`;

export function Box(props) {
  let style = {};

  boxStyleProps.forEach((key) => {
    if (props[key]) {
      style[key] = props[key];
    }
  });

  if (props.col) {
    style.flex = 1;
    style.flexBasis = "auto";
    style.flexDirection = "column";
    if (!props.noPadding) {
      style.paddingLeft = 5;
      style.paddingRight = 5;
      style.paddingTop = 8;
      style.paddingBottom = 8;
    }
  }

  if (props.row) {
    style.flex = 1;
    style.flexBasis = "auto";
    style.flexDirection = "row";
    if (!props.noMargin) {
      style.marginLeft = -5;
      style.marginRight = -5;
    }
  }

  if (props.autoWidth) {
    style.flex = 0;
  }

  // Style override
  if (props.style) {
    style = {
      ...style,
      ...props.style,
    };
  }

  const content = (
    <BoxContainer {...props} style={style}>
      {props.children}
    </BoxContainer>
  );

  return content;
}
