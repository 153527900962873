import React from "react";
import styled from "styled-components";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { SectionWrapper, WidthWrapper, CardsWrapper } from "./Section2";
import { breakPoints } from "../theme";

const StyledNumber = styled.div`
  color: ${(props) => props.theme.darkBlue};
  font-size: 40px;
  margin-right: 16px;
  font-weight: bold;
`;
const StyledLink = styled(Link)`
  color: ${(props) => props.theme.darkBlue};
  font-size: 14px;

  &:hover {
    color: ${(props) => props.theme.brandBlue};
  }
`;
const CardWrapper = styled.div`
  height: 100%;
  padding: 24px;
  background: ${(props) => props.theme.btnPrimaryColor};
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.04);

  span {
    font-family: "Telex", sans-serif;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.brandBlue};

  svg {
    margin-right: 8px;
  }
  img {
    margin-right: 8px;
  }
`;

export const StyledLogoWrapper = styled(LogoWrapper)`
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  color: ${(props) => props.theme.text_greyDark};
  margin-bottom: 24px;
  svg {
    margin-right: 16px;
  }
`;
export const Section = styled(SectionWrapper)`
  margin-top: 0;
  transition: 0.5s;
`;

export const StyledWidthWrapper = styled(WidthWrapper)`
  padding-top: 0;
`;
const StyledCardsWrapper = styled(CardsWrapper)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 272px;
  grid-gap: 40px;
  justify-content: start;
  @media (max-width: ${breakPoints.xl}px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 24px;
  }
`;

const StyledTitle = styled.div`
  font-size: 2em;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 2em;
  @media (max-width: ${breakPoints.sm}px) {
    padding-bottom: 40px;
  }
`;
export default function ThirdSection() {
  const { t } = useTranslation();
  return (
    <Section>
      <StyledWidthWrapper>
        <StyledTitle>{t("credentials.howItWorks")}</StyledTitle>
        <StyledCardsWrapper>
          <CardWrapper>
            <StyledLogoWrapper>
              <StyledNumber>1</StyledNumber>
              {t("credentials.step1.title")}
            </StyledLogoWrapper>
            <span>{t("credentials.step1.description")}</span>
          </CardWrapper>

          <CardWrapper>
            <StyledLogoWrapper>
              <StyledNumber>2</StyledNumber> {t("credentials.step2.title")}
            </StyledLogoWrapper>
            <span>
              <Trans>
                on <StyledLink to="/dashboard">KeyFi</StyledLink>
              </Trans>
            </span>
          </CardWrapper>

          <CardWrapper>
            <StyledLogoWrapper>
              <StyledNumber>3</StyledNumber>
              {t("credentials.step3.title")}
            </StyledLogoWrapper>
            <span>
              <Trans>
                our <StyledLink to="/dashboard">KeyFi</StyledLink>
              </Trans>
              <Trans>
                with <StyledLink to="/dashboard">KeyFi</StyledLink>
              </Trans>
            </span>
          </CardWrapper>
        </StyledCardsWrapper>
      </StyledWidthWrapper>
    </Section>
  );
}
